import React, { useEffect, useRef } from "react";
import { MapInfoBox, MapInfoBoxContainer } from "./MapBoxes";
import { ArrowsAngleContract, MapFill } from "react-bootstrap-icons";
import { WMSTileLayer } from "react-leaflet";
import { getUSGSURL, USGS_ATTR } from "./BackgroundLayersControl";
import { WaterbodyMarkerList } from "../../components/discovery/WaterbodyMarkerList";
import { Form, Table } from "react-bootstrap";
import { ControlPosition, DomEvent } from "leaflet";
import { controlPositionToClassnames } from "./LeafletHelpers";
import { useCookies } from "react-cookie";
import { LoggedInUserContext } from "../../App";
import { REMOTE_API_URL } from "../../constants";

const SELECTED_DATA_LAYERS_COOKIE_NAME = 'data_layers';
const DEFAULT_ENABLED_LAYERS = ['usgsHydroOld', 'usgsGovUnits', 'waterbodies']

// Required in order for overlays to show up over the background
const PANE = 'overlayPane';

export class DataLayer {
  name: string;
  element: JSX.Element;
  requiresLogin: boolean;


  constructor(name: string, element: JSX.Element, requiresLogin: boolean = false) {
    this.name = name;
    this.element = element;
    this.requiresLogin = requiresLogin;
  }
}

export const DATA_LAYERS: { [key: string]: DataLayer } = {
  // getUSGSURL('nhd', 'hydro.nationalmap.gov') is lower quality for some reason
  usgsHydroOld: new DataLayer('USGS Hydrography Old', (<WMSTileLayer pane={PANE} url={getUSGSURL('USGSHydroCached')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),
  usgsHydro: new DataLayer('USGS Hydrography', (<WMSTileLayer pane={PANE} url={getUSGSURL('nhd', 'hydro.nationalmap.gov')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),
  usgsGovUnits: new DataLayer('USGS Borders', (<WMSTileLayer pane={PANE} url={getUSGSURL('govunits', 'carto.nationalmap.gov')} layers='0' attribution={USGS_ATTR} format='image/png' transparent />)),

  waterbodies: new DataLayer('Waterbodies', (<WaterbodyMarkerList />), true),
  powerTransmissionLines: new DataLayer('Powerlines', (<WMSTileLayer pane={PANE} url={REMOTE_API_URL + 'geoserver/data/wms'} layers='power_transmission_lines' format='image/png' transparent />), true),
}

function DataLayersSelection({ checkedLayersCallback, disabledLayers, checkedLayers }: { checkedLayersCallback: (layers: string[]) => void, disabledLayers?: DataLayer[], checkedLayers: string[] }) {
  const user = React.useContext(LoggedInUserContext);
  return (
    <Table striped bordered hover>
      <tbody>
        {
          Object.entries(DATA_LAYERS)
            .filter(([_, layer]) => !disabledLayers?.includes(layer))
            .filter(([_, layer]) => !layer.requiresLogin || user)
            .map(([id, layer]) => (
              <tr key={id}>
                <td>{layer.name}</td>
                <td>
                  <Form.Switch
                    id={id}
                    checked={checkedLayers?.includes(id)}
                    onChange={(e) => checkedLayersCallback(e.target.checked ? [...(checkedLayers), e.target.id] : checkedLayers.filter((id) => id !== e.target.id))} />
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  )
}

export function DataLayersControl({ disabledLayers, position = 'topright' }: { disabledLayers?: DataLayer[], position?: ControlPosition }) {
  const [expanded, setExpanded] = React.useState(false);
  const [cookies, setCookie] = useCookies([SELECTED_DATA_LAYERS_COOKIE_NAME]);

  const checkedLayers: string[] = cookies.data_layers || DEFAULT_ENABLED_LAYERS;

  // Prevents clicks on this element from propagating to the map
  const divContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divContainer.current) {
      DomEvent.disableClickPropagation(divContainer.current);
      DomEvent.disableScrollPropagation(divContainer.current);
    }
  });

  return (
    <div ref={divContainer}>
      <MapInfoBoxContainer className={controlPositionToClassnames(position)}>
        <MapInfoBox style={{}}>
          {
            expanded ?
              (<>
                <ArrowsAngleContract size={22} onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} />
                <h4>Available Layers</h4>
                <DataLayersSelection checkedLayersCallback={(layers) => setCookie(SELECTED_DATA_LAYERS_COOKIE_NAME, layers, { path: '/' })} disabledLayers={disabledLayers} checkedLayers={checkedLayers} />
              </>)
              :
              <MapFill size={32} onClick={() => setExpanded(true)} style={{ cursor: 'pointer' }} />
          }
        </MapInfoBox>
      </MapInfoBoxContainer>
      {
        checkedLayers.filter((id) => !disabledLayers?.includes(DATA_LAYERS[id])).map((id) => {
          return <div key={id}>{DATA_LAYERS[id].element}</div>
        })
      }
    </div>
  );
}
