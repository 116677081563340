import { Icon, LeafletEventHandlerFnMap } from "leaflet";
import { Waterbody } from "../../utils/WaterbodyClient";
import { FeatureGroup, Marker, Tooltip } from "react-leaflet";
import { useFetcher } from "react-router-dom";
import { useEffect } from "react";

export function WaterbodyMarkerList({ skipWaterbody, selectNewWaterbody }: { skipWaterbody?: Waterbody, selectNewWaterbody?: (wb: Waterbody) => void }): JSX.Element {
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.state === "idle" && !fetcher.data) {
      fetcher.load("/data/waterbodies");
    }
  }, [fetcher]);

  function clickHandler(wb: Waterbody): LeafletEventHandlerFnMap {
    return {
      click() {
        if (selectNewWaterbody)
          selectNewWaterbody(wb);
      },
    };
  }

  const waterbodies = fetcher.data as Waterbody[];

  return <FeatureGroup>{
    waterbodies && waterbodies
      .filter(wb => !(skipWaterbody?.id === wb.id))
      .map(wb =>
      (
        <Marker key={wb.id} position={wb.location.coordinates as [number, number]} eventHandlers={clickHandler(wb)} icon={new Icon({ iconUrl: "/droplet-fill.svg", iconSize: [16, 16], iconAnchor: [8, 8] })}>
          <Tooltip>{wb.getTooltipContent()}</Tooltip>
        </Marker>
      )
      )
  }
  </FeatureGroup>
}
