import axios, { AxiosResponse } from 'axios';
import { REMOTE_API_URL } from '../constants';
import { ProjectClient } from './ProjectClient';
import { WaterbodyClient } from './WaterbodyClient';

export class ApiClient {
  private axiosInstance;

  constructor(token: string, apiURL: string = REMOTE_API_URL) {
    this.axiosInstance = axios.create({ baseURL: apiURL, headers: { 'Authorization': 'Bearer ' + token } });
  }

  async get<T = any>(path: string): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(path);
  }

  async post<T = any>(path: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(path, data);
  }

  async put<T = any>(path: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(path, data);
  }

  async delete<T = any>(path: string): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(path);
  }

  getProjectClient(): ProjectClient {
    return new ProjectClient(this);
  }
  
  getWaterbodyClient(): WaterbodyClient {
    return new WaterbodyClient(this);
  }
}
