import { Marker } from 'react-leaflet';
import { Waterbody } from '../../utils/WaterbodyClient';
import { Icon } from 'leaflet';

export function DraggableWaterbodyMarker({ waterbody } : { waterbody: Waterbody }): JSX.Element {
  return (
    <Marker
      draggable
      eventHandlers={{ dragend: (e) => waterbody.location = {type: 'Point', coordinates: [e.target.getLatLng().lat, e.target.getLatLng().lng]} }}
      position={waterbody.location.coordinates as [number, number]}
      icon={new Icon({iconUrl: "/droplet-fill-red.svg", iconSize: [16, 16], iconAnchor: [8, 8]})}
    />
  );
};
