// Editor for map layers
// Uses leaflet-draw, see https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html
import { useEffect } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { EditControl, } from 'react-leaflet-draw'
import useStateRef from 'react-usestateref';
import { Project } from '../../utils/ProjectClient';
import { readableAreaMetric } from '../../utils/GeoUtils';


// Generate popup content based on layer type
// - Returns HTML string, or null if unknown object
var getPopupContent = function (layer: L.Layer) {

  // Rectangle/Polygon - area
  if (layer instanceof L.Polygon) {
    var latlngs = layer.getLatLngs()[0] as L.LatLng[];
    var area = L.GeometryUtil.geodesicArea(latlngs);
    return 'Area: ' + readableAreaMetric(area);
  }

  // Polyline - distance
  if (layer instanceof L.Polyline) {
    latlngs = layer.getLatLngs() as L.LatLng[];
    if (latlngs.length < 2) {
      return 'Distance: N/A';
    }

    var distance = 0;
    for (var i = 0; i < latlngs.length - 1; i++) {
      distance += latlngs[i].distanceTo(latlngs[i + 1]);
    }
    return 'Distance: ' + L.GeometryUtil.readableDistance(distance, true);
  }

  return 'Unnable to calculate stats';
};

function _onCreated(e: L.DrawEvents.Created): void {
  e.layer.bindPopup(getPopupContent(e.layer));
}

function _onEdited(e: L.DrawEvents.Edited, editorGroup: L.FeatureGroup | undefined, layerUpdateCallback: (layers: L.Layer[]) => void): void {
  e.layers.eachLayer((layer: L.Layer) => {
    layer.setPopupContent(getPopupContent(layer));
  });

  if (editorGroup) {
    layerUpdateCallback(editorGroup.getLayers());
  }
}

interface ProjectEditorMapProps {
  layerUpdateCallback: (layers: L.Layer[]) => void,
  project?: Project
}

export default function ProjectEditorTool({ layerUpdateCallback, project }: ProjectEditorMapProps): JSX.Element {
  const map = useMap();
  const [editorGroup, setEditorGroup] = useStateRef<L.FeatureGroup>();

  // When the project is first loaded, add the features to the editor group
  useEffect(() => {
    if (!project || !editorGroup || editorGroup.getLayers().length > 0) {
      return;
    }
    console.log('adding features to editor group', project.features);
    project.toFeatureGroup().getLayers().forEach(layer => {
      layer.bindPopup(getPopupContent(layer));
      editorGroup.addLayer(layer);
    });
    layerUpdateCallback(editorGroup.getLayers())

    // You can't get the bounds of an empty group
    if (editorGroup.getLayers().length > 0) {
      map.flyToBounds(editorGroup.getBounds());
    }
  }, [project, editorGroup, layerUpdateCallback, map]);

  useEffect(() => {
    if (!editorGroup) {
      return;
    }

    function onLayerChange(event: L.LeafletEvent) {
      layerUpdateCallback(event.target.getLayers());
    }

    editorGroup.on('layeradd layerremove', onLayerChange);
    return () => {
      editorGroup.off('layeradd layerremove', onLayerChange)
    }
  }, [editorGroup, layerUpdateCallback])


  return (
    <FeatureGroup ref={(ref) => { if (ref) setEditorGroup(ref) }}>
      <EditControl
        position='bottomleft'
        onEdited={(e) => _onEdited(e, editorGroup, layerUpdateCallback)}
        onCreated={_onCreated}
        draw={{
          circle: false,
          circlemarker: false,
          marker: false,
          polyline: {
            showLength: true,
          },
          polygon: {
            showArea: true,
            showLength: true,
          },
        }}
      />
    </FeatureGroup>
  )
}
