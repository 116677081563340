import { Feature, Geometry } from "geojson";
import { ApiClient } from "./ApiClient";
import L, { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"


// When a network request returns a data packet, we can tell it to return the data as a type. We can't use Project directly for that
// because it will be missing everything except the fields.
interface ProjectData {
  id: number;
  name: string;
  features: Feature<Geometry>[];
  owner: string;
  waterOwner: string;
  waterOwnerType: string;
  waterOwnerContactInfo: string;
  usageType: string;
  borderType: string;
  waterbodyType: string;
  classification: string;
  securityType: string[];
}

export class Project {
  id: number;
  name: string;
  features: Feature<Geometry>[];
  owner: string;
  waterOwner: string;
  waterOwnerType: string;
  waterOwnerContactInfo: string;
  usageType: string;
  borderType: string;
  waterbodyType: string;
  classification: string;
  securityType: string[];

  constructor(
    id: number,
    name: string,
    features: Feature<Geometry>[],
    owner: string,
    waterOwner: string,
    waterOwnerType: string,
    waterOwnerContactInfo: string,
    usageType: string,
    borderType: string,
    waterbodyType: string,
    classification: string,
    securityType: string[]) {
    this.id = id;
    this.name = name;
    this.features = features;
    this.owner = owner;
    this.waterOwner = waterOwner;
    this.waterOwnerType = waterOwnerType;
    this.waterOwnerContactInfo = waterOwnerContactInfo;
    this.usageType = usageType;
    this.borderType = borderType;
    this.waterbodyType = waterbodyType;
    this.classification = classification;
    this.securityType = securityType;
  }

  static fromRaw(inst: ProjectData): Project {
    return new Project(
      inst.id,
      inst.name,
      inst.features,
      inst.owner,
      inst.waterOwner,
      inst.waterOwnerType,
      inst.waterOwnerContactInfo,
      inst.usageType,
      inst.borderType,
      inst.waterbodyType,
      inst.classification,
      inst.securityType
    );
  }

  toFeatureGroup(color?: string): L.FeatureGroup {
    const comonOptions: L.PolylineOptions = {};
    if (color) {
      comonOptions.color = color;  // Must only be set if there is a color, otherwise it overrides the default
    }

    const featureGroup = new L.FeatureGroup();
    for (const feature of this.features) {
      switch (feature.geometry.type) {
        case 'Polygon':
          featureGroup.addLayer(new L.Polygon(feature.geometry.coordinates.map((poly) => poly.map((coord) => new L.LatLng(coord[1], coord[0]))), comonOptions));
          break;
        case 'LineString':
          featureGroup.addLayer(new L.Polyline(feature.geometry.coordinates.map((coord) => new L.LatLng(coord[1], coord[0])), comonOptions));
          break;
        default:
          throw new Error("Unknown geometry type: " + feature.geometry.type);
      }
    }
    return featureGroup;
  }

  getMarker(): L.Marker {
    return L.marker(this.toFeatureGroup().getBounds().getCenter(), { icon: new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] }) })
  }

  getTooltipContent(): ((layer: L.Layer) => L.Content) | L.Tooltip | L.Content {
    return `Project: ${this.name || '...'}<br/>
    Water Owner: ${this.waterOwner || '...'}<br/>
    Water Owner Type: ${this.waterOwnerType || '...'}<br/>
    Water Owner Contact Info: ${this.waterOwnerContactInfo || '...'}<br/>
    Usage Type: ${this.usageType || '...'}<br/>
    Border Type: ${this.borderType || '...'}<br/>
    Waterbody Type: ${this.waterbodyType || '...'}<br/>
    Classification: ${this.classification || '...'}<br/>
    Security Type: ${this.securityType || '...'}`;
  }
}

export class ProjectClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getProject(id: number): Promise<Project> {
    console.log('getting project', id);
    if (!id || id < 0) {
      throw new Error('invalid project id ' + id);
    }
    return Project.fromRaw((await this.apiClient.get<ProjectData>('api/project/' + id)).data);
  }

  async getProjects(): Promise<Project[]> {
    console.log('getting projects');
    return (await this.apiClient.get<ProjectData[]>('api/project')).data.map(Project.fromRaw);
  }

  async createOrUpdateProject(project: Project): Promise<Project> {
    var cleaned: object = project;
    if (project.id < 0) {
      const { id, ...noId } = project
      cleaned = noId
    }
    console.log('Creating/updating project', cleaned);
    return Project.fromRaw((await this.apiClient.post<ProjectData>('api/project', cleaned)).data);
  }

  async deleteProject(id: number): Promise<void> {
    console.log('deleting project', id);
    if (!id || id < 0) {
      throw new Error('invalid project id ' + id);
    }
    await this.apiClient.delete('api/project/' + id);
  }
}