import { Button, Container, Nav, NavDropdown, NavItem, NavLink, Navbar } from 'react-bootstrap';
import { REMOTE_API_URL } from '../constants';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { LoggedInUserContext } from '../App';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';


export default function NavHeader(): JSX.Element {
  const from = window.location.href;
  const navigate = useNavigate();
  const loggedInUser = useContext(LoggedInUserContext);

  return (
    <Navbar expand='lg' bg='primary'>
      <Container fluid>
        <Navbar.Brand href='/'>
          <img
            src='/LogoName2.png'
            height='30'
            className='d-inline-block align-top'
            alt='Watts on Water'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <NavbarCollapse>
          <Nav>
            <NavItem>
              <NavLink onClick={() => navigate('/projects')}>Projects</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => navigate('/discover')}>Discover</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink onClick={() => navigate('/users')}>Users</NavLink>
            </NavItem> */}
          </Nav>
        </NavbarCollapse>
        <Navbar.Collapse className='justify-content-end'>
          <Nav>
            {
              loggedInUser ?
                <NavDropdown title={loggedInUser.username}>
                  <NavDropdown.Item onClick={() => navigate('/profile')}>Profile</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => { loggedInUser.logout(); navigate('/') }}>Logout</NavDropdown.Item>
                </NavDropdown> :
                <Button variant='secondary' href={REMOTE_API_URL + 'auth/login?redirect=' + from} size='sm'>Login</Button>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}