import { useLoaderData } from "react-router-dom";
import { Project } from "../../utils/ProjectClient";
import { Card, Col, Container, Row } from "react-bootstrap";
import { calculatePowerGeneration, calculateYearlyEvaporation, readableAreaMetric, readablePower, readableVolumeMetric, sumAreas } from "../../utils/GeoUtils";
import { Power, Water } from "react-bootstrap-icons";
import { FeatureGroup, MapContainer, WMSTileLayer } from "react-leaflet";
import styles from '../../generic/mapping/Map.module.css'
import React from "react";
import L from "leaflet";
import { getUSGSURL, USGS_ATTR } from "../../generic/mapping/BackgroundLayersControl";

export function ProjectReport() {
  const project = useLoaderData() as Project;
  const [map, setMap] = React.useState<L.Map | null>(null);
  const [projectFG, setProjectFG] = React.useState<L.FeatureGroup | null>(null);

  React.useEffect(() => {
    if (map) {
      map.flyToBounds(project.toFeatureGroup().getBounds(), { animate: false });
    }
  }, [map, project]);

  React.useEffect(() => {
    if (projectFG) {
      projectFG.clearLayers();
      projectFG.addLayer(project.toFeatureGroup());
    }
  }, [projectFG, project]);

  const area = sumAreas(project.toFeatureGroup().getLayers());

  return (
    <Container>
      <h1 className='m-3'>Project <b>{project.name}</b></h1>
      <Row className='flex-fill text-center' style={{ minHeight: '0' }}>
        <Col className='col-9'>
          <MapContainer
            center={new L.LatLng(0, 0)}
            zoom={0}
            maxZoom={25}
            minZoom={4}
            zoomSnap={0.5}
            className={styles['map']}
            zoomControl={false}
            ref={setMap}
            dragging={false}>
            <FeatureGroup ref={setProjectFG} />
            <WMSTileLayer url={getUSGSURL('USGSImageryTopo')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map?.getMaxZoom()} />
          </MapContainer>
        </Col>
        <Col>
          <Row className='text-success'>
            <Row>
              <h2>Installed Power</h2>
            </Row>
            <Row>
              <Power size={64} />
            </Row>
            <Row>
              <h3>{readablePower(calculatePowerGeneration(area))}</h3>
            </Row>
          </Row>
          <Row className='text-primary'>
            <Row>
              <h2>Water Conserved</h2>
            </Row>
            <Row>
              <Water size={64} />
            </Row>
            <Row>
              <h3>{readableVolumeMetric(calculateYearlyEvaporation(area))}</h3>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Project Details</Card.Title>
              <Card.Text>Creator: {project.owner}</Card.Text>
              <Card.Text>Water Owner: {project.waterOwner}</Card.Text>
              <Card.Text>Water Owner Type: {project.waterOwnerType}</Card.Text>
              <Card.Text>Water Owner Contact Info: {project.waterOwnerContactInfo}</Card.Text>
              <Card.Text>Usage Type: {project.usageType}</Card.Text>
              <Card.Text>Border Type: {project.borderType}</Card.Text>
              <Card.Text>Waterbody Type: {project.waterbodyType}</Card.Text>
              <Card.Text>Classification: {project.classification}</Card.Text>
              <Card.Text>Security Type: {project.securityType.join(', ')}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          {/* TODO: This card should calculate the stats from DB calls instead of these rough estimates */}
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Estimated Stats:</Card.Title>
              <Card.Text>Area: {readableAreaMetric(area)}</Card.Text>
              <Card.Text>Power: {readablePower(calculatePowerGeneration(area))}</Card.Text>
              <Card.Text>Annual Evaporation Savings: {"~" + readableVolumeMetric(calculateYearlyEvaporation(area))}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}