import { Container, Row, Col, Table } from 'react-bootstrap';
import { LoggedInUser } from '../../App';
import { useLoaderData } from 'react-router-dom';

interface UserData {
  id: number;
  username: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
}

export async function currentUserDataLoader(loggedInUser?: LoggedInUser | null): Promise<UserData> {
  if (!loggedInUser) {
    // We know that other pieces will handle the login redirect
    return Promise.resolve({} as UserData);
  }

  const res = await loggedInUser.apiClient.get<UserData>('api/user/' + loggedInUser.username);
  if (res.status !== 200) {
    throw new Error('Unexpected response code: ' + res.status);
  }

  return res.data;
}

export function Profile(): JSX.Element {
  const userData = useLoaderData() as UserData;

  return (
    <Container>
      <h1>Your Profile</h1>
      <Row className='flex-fill flex-grow-1'>
        <Col className='col-2'>
          Options Coming Soon! Or not. You aren't an admin, so probably not.
        </Col>
        <Col className='gx-0'>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Username</td>
                <td>{userData.username}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td>{userData.role}</td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{userData.firstName}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{userData.lastName}</td>
              </tr>
              <tr>
                <td>Active</td>
                <td>{userData.isActive ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
