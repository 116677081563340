import { Outlet } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap';
import NavHeader from './NavHeader';

export function Layout() {
  // Class definitions:
  // vh-100 d-flex and `fluid` forces the container to use the whole screen
  // flex-column makes the rows go in order vertically
  // flex-fill flex-grow-1: used to force the row to use the remaining height
  // col-2: makes the sidebar 2 columns wide
  // gx-0: removes the gutter between the columns
  return (
    <Container fluid className='App vh-100 d-flex flex-column'>
      <Row>
        <NavHeader />
      </Row>
      <Outlet />
    </Container>
  )
}

export function MapSidebar({ children }: { children: React.ReactNode }) {
  return (
    <Col className='col-2 p-2 overflow-scroll' style={{ maxHeight: '100%', minWidth: '250px', maxWidth: '300px' }}>
      {children}
    </Col>
  )
}
