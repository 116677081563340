import L from "leaflet"
import { MapContainer, ZoomControl } from "react-leaflet"
import { BackgroundLayersControl } from "../../generic/mapping/BackgroundLayersControl"
import styles from '../../generic/mapping/Map.module.css'
import { Ref } from "react"
import { MapPosition, MapPositionTracker } from "../../generic/mapping/MapPosition"
import { DataLayer, DataLayersControl } from "../../generic/mapping/DataLayersControl"

type ProjectMapProps = {
  children?: React.ReactNode
  mapRef?: Ref<L.Map>
  zoomOnDblclk?: boolean
  disabledLayers?: DataLayer[]
}

export default function ProjectMap({ children, mapRef, zoomOnDblclk = true, disabledLayers }: ProjectMapProps): JSX.Element {
  return (
    <MapContainer
      center={MapPosition.coords}
      zoom={MapPosition.zoom}
      doubleClickZoom={zoomOnDblclk}
      maxZoom={25}
      minZoom={4}
      zoomSnap={0.5}
      scrollWheelZoom={true}
      className={styles['map']}
      zoomControl={false}
      ref={mapRef}>
      <ZoomControl position='bottomright' />
      <BackgroundLayersControl position='bottomright' />
      <MapPositionTracker />
      <DataLayersControl disabledLayers={disabledLayers} />
      {children}
    </MapContainer>
  )
}
