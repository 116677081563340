import { Card } from "react-bootstrap";

export function MapInfoBoxContainer({ children, className }: { children: React.ReactNode, className?: string }): JSX.Element {
  return (
    <div className={className || 'leaflet-top leaflet-left'}>
      {children}
    </div>
  ); 
}

export function MapInfoBox({ children, style={ width: '250px', opacity: .8 } }: { children: React.ReactNode, style?: React.CSSProperties }): JSX.Element {
  return (
    <Card className='leaflet-control leaflet-bar' style={style}>
      <Card.Body style={{ width: '100%' }}>
        {children}
      </Card.Body>
    </Card>
  );
}