import L from "leaflet"
import { useMapEvents } from "react-leaflet"

/**
 * A singleton class which holds the last recorded position of the map. This allows us to keep the map from resetting to the initial position when we go to a new page.
 * This intentionally only stores the state in memory, so that when a user refreshes the page they get a clean slate.
 * 
 * In addition, we don't use React.useState because we dont want react to try to recreate the map every time the user pans the map, we only want to use this when the map is freshly created.
 * 
 * To use this, the map should include a MapPositionTracker child component, and set
 * ```
 * center={MapPosition.coords}
 * zoom={MapPosition.zoom}
 * ```
 */
class MapPosition {
  coords: L.LatLng
  zoom: number

  constructor() {
    // These serve as the map default locations.
    this.coords = new L.LatLng(32.71, -115.0376486051686)
    this.zoom = 13
  }
}
var mapPosition = new MapPosition()
export { mapPosition as MapPosition }

/**
 * A component which tracks the position of the map and stores it in the mapPosition singleton.
 * 
 * This component does not render anything, it is only a React component so that it can use map context. As such, it must be a child of a Map.
 */
export function MapPositionTracker(): JSX.Element {
  useMapEvents(
    {
      moveend(e) {
        mapPosition.coords = e.target.getCenter();
        mapPosition.zoom = e.target.getZoom();
      },
    }
  )

  return (<></>)
}
