import { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Card, Form } from 'react-bootstrap';
import { LoggedInUserContext } from '../../App';
import { Waterbody } from '../../utils/WaterbodyClient';
import { BorderType, SecurityType, UsageType, WaterOwnerType, Classification, WaterbodyType } from '../../types/waterbody.traits';
import { useNavigate, useSubmit, Form as RouterForm } from 'react-router-dom';
import { confirmBeforeDelete } from '../../generic/form';

function handleError(err: any): void {
  console.error(err);
  if (err.response.status >= 400 && err.response.status < 500) {
    alert('An unknown issue occurred while saving the waterbody.');
  } else {
    alert('An unknown error occurred.');
  }
}

export default function DiscoveryToolSidebar({ waterbody }: { waterbody: Waterbody }): JSX.Element {
  const nav = useNavigate();
  const [waterbodyName, setWaterbodyName] = useState<string>('');
  const [waterOwner, setWaterOwner] = useState<string>('');
  const [waterOwnerType, setWaterOwnerType] = useState<WaterOwnerType>(WaterOwnerType.UNSPECIFIED);
  const [waterOwnerContactInfo, setWaterOwnerContactInfo] = useState<string>('');
  const [usageType, setUsageType] = useState<UsageType>(UsageType.UNSPECIFIED);
  const [borderType, setBorderType] = useState<BorderType>(BorderType.UNSPECIFIED);
  const [waterbodyType, setWaterbodyType] = useState<WaterbodyType>(WaterbodyType.UNSPECIFIED);
  const [classification, setClassification] = useState<Classification>(Classification.UNSPECIFIED);
  const [securityType, setSecurityType] = useState<string[]>([]);

  const submit = useSubmit();
  const loggedInUser = useContext(LoggedInUserContext);

  // When the waterbody is first loaded, set the waterbody name and owner
  useEffect(() => {
    if (waterbody) {
      setWaterbodyName(waterbody.name || '');
      setWaterOwner(waterbody.waterOwner || '');
      setWaterOwnerType(waterbody.waterOwnerType);
      setWaterOwnerContactInfo(waterbody.waterOwnerContactInfo || '');
      setUsageType(waterbody.usageType);
      setBorderType(waterbody.borderType);
      setWaterbodyType(waterbody.waterbodyType);
      setClassification(waterbody.classification);
      setSecurityType(waterbody.securityType || []);
    }
  }, [waterbody]);


  function saveWaterbody(): void {
    waterbody.name = waterbodyName;
    waterbody.waterOwner = waterOwner;
    waterbody.waterOwnerType = waterOwnerType;
    waterbody.waterOwnerContactInfo = waterOwnerContactInfo;
    waterbody.usageType = usageType;
    waterbody.borderType = borderType;
    waterbody.classification = classification;
    waterbody.securityType = securityType;

    // In case this is a new wb, when complete we navigate to the edit page for the wb so that subsequent saves will update it.
    loggedInUser?.apiClient.getWaterbodyClient().createOrUpdateWaterbody(waterbody).then(wb => {
      // TODO: use submit to actually submit data.
      submit({ intent: "save" }, {
        method: "put",
        action: "/data/waterbodies/" + wb.id,
      });
      nav('/discover/');

    }).catch(handleError);
  }

  return (
    <Card>
      <Card.Body>
        <RouterForm method='post' action={'/data/waterbodies/' + (waterbody?.id || '')}>
          <h2>Options:</h2>
          <Form.Group className="mb-3">
            <Form.Label>Waterbody Name</Form.Label>
            <Form.Control type="text" placeholder="My Aquavoltaics Waterbody" value={waterbodyName} onChange={(e) => setWaterbodyName(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Water Owner</Form.Label>
            <Form.Control type="text" value={waterOwner} onChange={(e) => setWaterOwner(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Water Owner Type</Form.Label>
            <Form.Select value={waterOwnerType.id} onChange={(e) => setWaterOwnerType(WaterOwnerType.fromId(e.target.value))}>
              {
                WaterOwnerType.allValues().map((key) =>
                  <option key={key.id} value={key.id}>{key.displayName}</option>
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Water Owner Contact Info</Form.Label>
            <Form.Control type="text" value={waterOwnerContactInfo} onChange={(e) => setWaterOwnerContactInfo(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Usage Type</Form.Label>
            <Form.Select value={usageType.id} onChange={(e) => setUsageType(UsageType.fromId(e.target.value))}>
              {
                UsageType.allValues().map((key) =>
                  <option key={key.id} value={key.id}>{key.displayName}</option>
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Border Type</Form.Label>
            <Form.Select value={borderType.id} onChange={(e) => setBorderType(BorderType.fromId(e.target.value))}>
              {
                BorderType.allValues().map((key) =>
                  <option key={key.id} value={key.id}>{key.displayName}</option>
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Waterbody Type</Form.Label>
            <Form.Select value={waterbodyType.id} onChange={(e) => setBorderType(WaterbodyType.fromId(e.target.value))}>
              {
                WaterbodyType.allValues().map((key) =>
                  <option key={key.id} value={key.id}>{key.displayName}</option>
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Classification</Form.Label>
            <Form.Select value={classification.id} onChange={(e) => setClassification(Classification.fromId(e.target.value))}>
              {
                Classification.allValues().map((key) =>
                  <option key={key.id} value={key.id}>{key.displayName}</option>
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Security Type</Form.Label>
            {
              SecurityType.allValues().map((key) => (
                <Form.Check key={key.id} type="checkbox" label={key.displayName} checked={securityType.includes(key.id)} onChange={(e) => setSecurityType(e.target.checked ? (securityType.concat(key.id)) : securityType.filter((e) => e !== key.id))} />
              ))
            }
          </Form.Group>

          <ButtonGroup>
            <Button variant='primary' type='submit' name='intent' value='save' onClick={saveWaterbody}>Save</Button>
            <Button variant='danger' type='submit' name='intent' value='delete' onClick={confirmBeforeDelete}>Delete</Button>
          </ButtonGroup>
        </RouterForm>
      </Card.Body>
    </Card>
  );
};
