import { Col, Row } from 'react-bootstrap';
import { Project } from '../../utils/ProjectClient';
import ProjectBrowserSidebar from './ProjectBrowserSidebar';
import { useLoaderData } from 'react-router';
import { FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import ProjectMap from './ProjectMap';
import { useEffect, useRef, useState } from 'react';
import { MapSidebar } from '../../generic/Layout';
import { ProjectInfoBox } from './ProjectInfoBox';

const PAN_DURATION_SECONDS = 2;

function randomColor(): string {
  return '#' + Math.random().toString(16).substr(-6);
}

export function ProjectBrowser() {
  const projects = useLoaderData() as Project[];
  const map = useRef<L.Map>(null);
  const [projectsParent, setProjectParent] = useState<L.FeatureGroup | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project>();

  function projectSelectionHandler(project: Project) {
    if (project.features.length) {
      setSelectedProject(project);
      map.current?.flyToBounds(project.toFeatureGroup().getBounds(), { duration: PAN_DURATION_SECONDS });
    }
  }

  useEffect(() => {
    console.log('setting projects', projects);
    if (projectsParent && projects) {
      projectsParent.clearLayers();
      for (const inst of projects) {
        console.log('adding project', inst, inst.toFeatureGroup);
        const fg = inst.toFeatureGroup(randomColor());
        fg.bindTooltip(inst.getTooltipContent());

        // Add a marker to the center of the project.
        // Make sure there is at least one existing layer, otherwise it is impossible to compute the center of the 
        // project, and we don't want to add a marker.
        if (fg.getLayers().length)
          fg.addLayer(inst.getMarker().addEventListener('click', () => projectSelectionHandler(inst)));

        projectsParent.addLayer(fg);
      }
    }
  }, [projectsParent, projects]);

  // TODO: Disable all overlays on the map to speed up panning
  // See App.tsx for class definitions
  return (
    <Row className='flex-fill flex-grow-1'>
      <MapSidebar>
        <ProjectBrowserSidebar map={map} projectSelectionHandler={projectSelectionHandler} />
      </MapSidebar>
      <Col className='gx-0'>
        <ProjectMap mapRef={map}>
          <FeatureGroup ref={setProjectParent} />
          {selectedProject && <ProjectInfoBox project={selectedProject} />}
        </ProjectMap>
      </Col>
    </Row>
  );
}
