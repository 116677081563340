import L from 'leaflet';
import { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { LoggedInUserContext } from '../../App';
import { useNavigate } from 'react-router';
import { Form as RouterForm } from 'react-router-dom';
import { Project } from '../../utils/ProjectClient';
import { BorderType, SecurityType, UsageType, WaterOwnerType, Classification, WaterbodyType } from '../../types/waterbody.traits';
import { layerToFeature } from '../../utils/GeoUtils';

function handleError(err: any): void {
  console.error(err);
  if (err.response.status === 409) {
    alert('An project with that name already exists.');
  } else if (err.response.status >= 400 && err.response.status < 500) {
    alert('An unknown issue occurred while saving the project.');
  } else {
    alert('An unknown error occurred.');
  }
}

interface ProjectSidebarProps {
  projectLayers: L.Layer[],
  project?: Project
}

export default function ProjectEditorSidebar({ projectLayers, project }: ProjectSidebarProps): JSX.Element {
  const [projectName, setProjectName] = useState<string>('');
  const [waterOwner, setWaterOwner] = useState<string>('');
  const [waterOwnerType, setWaterOwnerType] = useState<string>(WaterOwnerType.UNSPECIFIED.id);
  const [waterOwnerContactInfo, setWaterOwnerContactInfo] = useState<string>('');
  const [usageType, setUsageType] = useState<string>(UsageType.UNSPECIFIED.id);
  const [borderType, setBorderType] = useState<string>(BorderType.UNSPECIFIED.id);
  const [waterbodyType, setWaterbodyType] = useState<string>(WaterbodyType.UNSPECIFIED.id);
  const [classification, setClassification] = useState<string>(Classification.UNSPECIFIED.id);
  const [securityType, setSecurityType] = useState<string[]>([]);


  const loggedInUser = useContext(LoggedInUserContext);
  const navigate = useNavigate();

  // When the project is first loaded, set the project name and owner
  useEffect(() => {
    if (project) {
      setProjectName(project.name);
      setWaterOwner(project.waterOwner || '');
      setWaterOwnerType(project.waterOwnerType || WaterOwnerType.UNSPECIFIED.id);
      setWaterOwnerContactInfo(project.waterOwnerContactInfo || '');
      setUsageType(project.usageType || UsageType.UNSPECIFIED.id);
      setBorderType(project.borderType || BorderType.UNSPECIFIED.id);
      setWaterbodyType(project.waterbodyType || WaterbodyType.UNSPECIFIED.id);
      setClassification(project.classification || Classification.UNSPECIFIED.id);
      setSecurityType(project.securityType || []);
    }
  }, [project]);

  function saveProject(): void {
    const features = projectLayers.map(layerToFeature);

    if (projectName.length === 0) {
      return;
    }

    const newProject = new Project(project ? project.id : -1,
      projectName,
      features,
      project ? project.owner : loggedInUser!.username,
      waterOwner,
      waterOwnerType,
      waterOwnerContactInfo,
      usageType,
      borderType,
      waterbodyType,
      classification,
      securityType);

    // In case this is a new project, when complete we navigate to the edit page for the project so that subsequent saves will update it.
    loggedInUser?.apiClient.getProjectClient().createOrUpdateProject(newProject).then(inst => navigate('/projects/')).catch(handleError);
  }

  return (
    <RouterForm method='post' action={'/projects/' + (project?.id || '') + 'edit'}>
      <h2>Options:</h2>
      <Form.Group className="mb-3">
        <Form.Label>Project Name</Form.Label>
        <Form.Control required type="text" placeholder="My Aquavoltaics Project" value={projectName} onChange={(e) => setProjectName(e.target.value)} isInvalid={projectName.length === 0} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Water Owner</Form.Label>
        <Form.Control type="text" value={waterOwner} onChange={(e) => setWaterOwner(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Water Owner Type</Form.Label>
        <Form.Select value={waterOwnerType} onChange={(e) => setWaterOwnerType(e.target.value)}>
          {
            WaterOwnerType.allValues().map((key) => {
              return <option value={key.id}>{key.displayName}</option>
            })
          }
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Water Owner Contact Info</Form.Label>
        <Form.Control type="text" value={waterOwnerContactInfo} onChange={(e) => setWaterOwnerContactInfo(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Usage Type</Form.Label>
        <Form.Select value={usageType} onChange={(e) => setUsageType(e.target.value)}>
          {
            UsageType.allValues().map((key) => {
              return <option value={key.id}>{key.displayName}</option>
            })
          }
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Border Type</Form.Label>
        <Form.Select value={borderType} onChange={(e) => setBorderType(e.target.value)}>
          {
            BorderType.allValues().map((key) => {
              return <option value={key.id}>{key.displayName}</option>
            })
          }
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Waterbody Type</Form.Label>
        <Form.Select value={waterbodyType} onChange={(e) => setWaterbodyType(e.target.value)}>
          {
            WaterbodyType.allValues().map((key) =>
              <option key={key.id} value={key.id}>{key.displayName}</option>
            )
          }
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Classification</Form.Label>
        <Form.Select value={classification} onChange={(e) => setClassification(e.target.value)}>
          {
            Classification.allValues().map((key) => {
              return <option value={key.id}>{key.displayName}</option>
            })
          }
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Security Type</Form.Label>
        {
          SecurityType.allValues().map((key) => {
            return <Form.Check type="checkbox" label={key.displayName} checked={securityType.includes(key.id)} onChange={(e) => setSecurityType(e.target.checked ? (securityType.concat(key.id)) : securityType.filter((e) => e !== key.id))} />
          })
        }
      </Form.Group>

      <ButtonGroup>
        <Button variant='primary' onClick={saveProject} name='intent' value='save'>Save</Button>
      </ButtonGroup>
    </RouterForm>
  );
};
