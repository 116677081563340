import { ControlPosition } from 'leaflet';

export function controlPositionToClassnames(position: ControlPosition): string {
  switch (position) {
    case 'topleft':
      return 'leaflet-top leaflet-left';
    case 'topright':
      return 'leaflet-top leaflet-right';
    case 'bottomleft':
      return 'leaflet-bottom leaflet-left';
    case 'bottomright':
      return 'leaflet-bottom leaflet-right';
  }
}
