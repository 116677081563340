import { LoggedInUser } from "../App";
import { Project } from "../utils/ProjectClient";
import { Waterbody } from "../utils/WaterbodyClient";

export function waterbodiesLoader(user?: LoggedInUser | null): Promise<Waterbody[]> | Response {
  if (!user) {
    // We know that other pieces will handle the login redirect
    return Promise.resolve([{} as Waterbody]);
  }
  return user.apiClient.getWaterbodyClient().getWaterbodies();
}

export function waterbodyLoader(id: number, user?: LoggedInUser | null): Promise<Waterbody> | Response {
  if (!user) {
    // We know that other pieces will handle the login redirect
    return Promise.resolve({} as Waterbody);
  }
  return user.apiClient.getWaterbodyClient().getWaterbody(id);
}
export function projectLoader(id: number, user?: LoggedInUser | null): Promise<Project> | Response {
  if (!user) {
    // We know that other pieces will handle the login redirect
    return Promise.resolve({} as Project);
  }
  return user.apiClient.getProjectClient().getProject(id);
}export function projectsLoader(user?: LoggedInUser | null): Promise<Project[]> | Response {
  if (!user) {
    // We know that other pieces will handle the login redirect
    return Promise.resolve([]);
  }
  return user.apiClient.getProjectClient().getProjects();
}

