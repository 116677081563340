import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type OAuthSuccessRedirectParams = {
  login: (token: string) => void;
}

export default function OAuthSuccessRedirect({ login }: OAuthSuccessRedirectParams): JSX.Element {
  let { accessToken, from } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      login(accessToken);
      navigate('/' + (from || ''), { replace: true });
    } else {
      throw new Error('No access token provided');
    }
  }, [accessToken, from, navigate, login])

  return (
    <div>Loading...</div>
  )
}
